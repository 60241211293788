<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import { mapState } from "vuex";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'BackOffice': 'Escritório Virtual',
      'Menu': 'Menu',
      'Dashboard': 'Painel',
      'Store': 'Loja',
      'Products': 'Produtos',
      'Cart': 'Carrinho',
      'My Orders': 'Meus Pedidos',
      'Categories': 'Categorias',
    },
    es: {
      'BackOffice': 'Escritório Virtual',
      'Menu': 'Menu',
      'Dashboard': 'Panel',
      'Store': 'Tienda',
      'Products': 'Productos',
      'Cart': 'Cesta',
      'My Orders': 'Mis Pedidos',
      'Categories': 'Categorias',
    }
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      categories: {
        loading: true,
        list: [],
        errored: false,
      }
    };
  },
  mounted: function () {
    document.body.setAttribute("data-sidebar", "light");
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("mm-active");
          }
        }
      }
    }

    this.getCategories()
  },
  methods: {
    getCategories() {
      if (!localStorage.categories) {
        api
          .get('store/categories')
          .then(response => {
            if (response.data.status == 'success') {
              this.categories.list = response.data.list
              this.categories.loading = false

              localStorage.categories = JSON.stringify(response.data.list)
            }
          })
          .catch(error => {
            this.categories.errored = error
            this.categories.loading = false
          })
      } else {
        this.categories.list = JSON.parse(localStorage.categories)
        this.categories.loading = false
      }
    }
  }
};
</script>

<template>
  <div id="sidebar-menu" class="pt-3">
    <ul id="side-menu" class="metismenu list-unstyled">
      <li class="bg-soft-default rounded-lg mb-2">
        <router-link tag="a" to="/" class="side-nav-link-ref border-0 mt-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
          <span class="notranslate">{{ t('BackOffice') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/store/featured" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M4 11h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1zm1-6h4v4H5V5zm15-2h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm-1 6h-4V5h4v4zm-9 12a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6zm-5-6h4v4H5v-4zm13-1h-2v2h-2v2h2v2h2v-2h2v-2h-2z">
            </path>
          </svg>
          <span class="notranslate">{{ t('Products') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/store/cart" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M21.822 7.431A1 1 0 0 0 21 7H7.333L6.179 4.23A1.994 1.994 0 0 0 4.333 3H2v2h2.333l4.744 11.385A1 1 0 0 0 10 17h8c.417 0 .79-.259.937-.648l3-8a1 1 0 0 0-.115-.921z"></path>
            <circle cx="10.5" cy="19.5" r="1.5"></circle>
            <circle cx="17.5" cy="19.5" r="1.5"></circle>
          </svg>
          <span class="notranslate">{{ t('Cart') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/store/orders" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M5.282 12.064c-.428.328-.72.609-.875.851-.155.24-.249.498-.279.768h2.679v-.748H5.413c.081-.081.152-.151.212-.201.062-.05.182-.142.361-.27.303-.218.511-.42.626-.604.116-.186.173-.375.173-.578a.898.898 0 0 0-.151-.512.892.892 0 0 0-.412-.341c-.174-.076-.419-.111-.733-.111-.3 0-.537.038-.706.114a.889.889 0 0 0-.396.338c-.094.143-.159.346-.194.604l.894.076c.025-.188.074-.317.147-.394a.375.375 0 0 1 .279-.108c.11 0 .2.035.272.108a.344.344 0 0 1 .108.258.55.55 0 0 1-.108.297c-.074.102-.241.254-.503.453zm.055 6.386a.398.398 0 0 1-.282-.105c-.074-.07-.128-.195-.162-.378L4 18.085c.059.204.142.372.251.506.109.133.248.235.417.306.168.069.399.103.692.103.3 0 .541-.047.725-.14a1 1 0 0 0 .424-.403c.098-.175.146-.354.146-.544a.823.823 0 0 0-.088-.393.708.708 0 0 0-.249-.261 1.015 1.015 0 0 0-.286-.11.943.943 0 0 0 .345-.299.673.673 0 0 0 .113-.383.747.747 0 0 0-.281-.596c-.187-.159-.49-.238-.909-.238-.365 0-.648.072-.847.219-.2.143-.334.353-.404.626l.844.151c.023-.162.067-.274.133-.338s.151-.098.257-.098a.33.33 0 0 1 .241.089c.059.06.087.139.087.238 0 .104-.038.193-.117.27s-.177.112-.293.112a.907.907 0 0 1-.116-.011l-.045.649a1.13 1.13 0 0 1 .289-.056c.132 0 .237.041.313.126.077.082.115.199.115.352 0 .146-.04.266-.119.354a.394.394 0 0 1-.301.134zm.948-10.083V5h-.739a1.47 1.47 0 0 1-.394.523c-.168.142-.404.262-.708.365v.754a2.595 2.595 0 0 0 .937-.48v2.206h.904zM9 6h11v2H9zm0 5h11v2H9zm0 5h11v2H9z">
            </path>
          </svg>
          <span class="notranslate">{{ t('My Orders') }}</span>
        </router-link>
      </li>
      <li class="d-none">
        <router-link tag="a" to="/store/franchises" class="side-nav-link-ref">
          <i class="bx bx-building-house"></i>
          <span class="notranslate">{{ t('Franquias') }}</span>
        </router-link>
      </li>
      <li v-for="(cat, id) in categories.list" :key="id">
        <router-link tag="a" :to="'/store/category/' + cat.id" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 11h14v2H5z"></path>
          </svg>
          <span class="notranslate">{{ cat.name }}</span>
        </router-link>
        <ul v-if="cat.childs" class="sub-menu" aria-expanded="false">
          <li v-for="(child, ch) in cat.childs" :key="ch">
            <router-link tag="a" :to="'/store/category/' + child.id" class="side-nav-link-ref">{{ child.name }}</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.py-2 {
  padding: .9rem 1.5rem .7rem !important;
}

.py-2 i,
.py-2 span {
  line-height: 0px !important;
  padding-bottom: 0 !important;
}

.py-2 i {
  margin-top: -4px;
}
</style>